import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import HeaderTeamsPage from "src/components/HeaderTeamsPage"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "./styles.module.scss"
import GetStarted from "src/components/Banners/GetStarted"
import Img from "gatsby-image"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <HeaderTeamsPage
        title={texts.header}
        description={texts.description}
        button={true}
      />
      <div className={styles.main}>
        {texts.whyflowTasks.map(task => (
          <div className={`content-wrapper ${styles.feature}`} key={task.title}>
            {task.icon && (
              <img
                src={require(`./icons/${task.icon}`)}
                className={styles.topIcon}
              />
            )}
            <h2 className={styles.title}>{task.title}</h2>
            <p className={styles.pagraph}>{task.description}</p>
            {task.image && (
              <Img
                fluid={task.image.childImageSharp.fluid}
                style={{
                  height: "100%",
                  width: task.width ? task.width : "100%",
                  maxWidth: task.maxW ? task.maxW : "none",
                }}
                className={styles.imgContainer}
              />
            )}
            {task.video && (
              <video
                autoPlay
                loop
                muted
                style={{ maxWidth: task.maxW ? task.maxW : "100%" }}
                className={styles.video}
              >
                <source
                  src={require(`./icons/${task.video}`)}
                  type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                />
              </video>
            )}
            {task.text1 && (
              <p
                dangerouslySetInnerHTML={{ __html: task.text1 }}
                className={`${styles.text} ${styles.textSpace}`}
              />
            )}

            {task.image1 && (
              <Img
                fluid={task.image1.childImageSharp.fluid}
                style={{
                  height: "100%",
                  width: "100%",
                  maxWidth: task.maxW1 ? task.maxW1 : "none",
                }}
                className={styles.imgContainer}
              />
            )}

            {task.text2 && (
              <p
                dangerouslySetInnerHTML={{ __html: task.text2 }}
                className={styles.text}
              />
            )}

            {task.list && (
              <div className={styles.grid}>
                {task.list.map((item: any, index: number) => (
                  <div className={styles.item} key={`${item.name}-${index}`}>
                    <img
                      src={require(`./icons/${item.icon}`)}
                      className={styles.itemIcon}
                    />
                    <h3 className={styles.itemName}>{item.name}</h3>
                    <p className={styles.itemDescription}>{item.description}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={`content-wrapper ${styles.banner}`}>
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        header
        description
        metaTitle
        metaDescription
        whyflowTasks {
          title
          description
          video
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1084) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          maxW
          width
          icon
          list {
            name
            description
            icon
          }
          text1
          text2
          image1 {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1084) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          maxW1
        }
      }
    }
  }
`

export default Template
