import React, { FC } from "react"
import * as styles from "./styles.module.scss"
import { Props } from "./types"
import { graphql, useStaticQuery } from "gatsby"
import { useComponentTexts } from "src/hooks/useComponentTexts"
import LogosList from "src/components/LogosList"
import { useSignUp } from "src/hooks/useSignUp"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"

const HeaderTeamsPage: FC<Props> = ({
  className,
  logosTitle,
  title,
  description,
  button = false,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allFile (filter: { relativePath: {regex: "/components/HeaderTeamsPage\/.+\\.yml/"}}) {
        nodes {
          childYaml {
            invalidEmailError
            unknownSignUpError
            freeFor30Days
            creditCard
            cancel
            signUp
            placeholderEmail
            buttonText
            logosTitle
          }
          relativePath
        }
      }
    }
  `)
  const texts = useComponentTexts(data)
  const signUp = useSignUp(texts)
  const { getPage } = usePages()
  return (
    <div className={`${styles.header} ${className}`}>
      <div className={`${styles.headerContent} content-wrapper`}>
        <h1 className={styles.title}>{title}</h1>
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {button ? (
          <Link
            to={getPage("signup").url}
            className={`button-new button-new--primary`}
          >
            {texts.buttonText}
          </Link>
        ) : (
          <>
            <div className={`${styles.separatorText}`}>
              {signUp.status === "error" ? signUp.error : ""}
            </div>
            <form
              className={`${styles.contentInput} ${signUp.status === "error" &&
                styles.error}`}
              {...signUp.formProps}
            >
              <input
                className={styles.emailInput}
                type="text"
                placeholder={texts.placeholderEmail}
                {...signUp.inputProps}
                autoFocus={true}
              />
              <button
                className={`button button-second ${styles.emailButton}`}
                type="submit"
                {...signUp.buttonProps}
              >
                {texts.signUp}
              </button>
            </form>
            <div className={styles.offerDescription}>
              <span>{texts.freeFor30Days}</span>
              <span>{texts.creditCard}</span>
              <span>{texts.cancel}</span>
            </div>
          </>
        )}

        <div className={styles.logosContainer}>
          <LogosList text={logosTitle ? logosTitle : texts.logosTitle} />
        </div>
      </div>
    </div>
  )
}

export default HeaderTeamsPage
